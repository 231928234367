import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import { useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router";
import { apiLogin, apiMe } from "../api/axios";
import { ILoginInput, ILoginOutput } from "../api/type";
import { FormButton } from "../component/form-button";
import { FormError } from "../component/form-error";
import { LOCALSTORAGE_TOKEN, UserRole, UserTestState } from "../constant";

export const TesterLogin = () => {
  const naviage = useNavigate();
  const queryClient = useQueryClient();
  const [isAgree, setIsAgree] = useState(false);

  useQuery("me", apiMe, {
    onSuccess: (data) => {
      if (data) {
        switch (data.testState) {
          case UserTestState.Pending:
            naviage(`/orientation?normid=${data.group[0].normId}`);
            break;

          case UserTestState.InProgress:
            naviage(`/test`);
            break;

          case UserTestState.Done:
            naviage(`/preview`);
            break;

          default:
            break;
        }
      }
    },
  });
  const {
    register,
    getValues,
    formState: { errors, isValid },
    handleSubmit,
  } = useForm<ILoginInput>({ mode: "onChange" });

  const loginMutation = useMutation(apiLogin, {
    onSuccess: (data: ILoginOutput) => {
      if (data.ok && data.token) {
        localStorage.setItem(LOCALSTORAGE_TOKEN, data.token);
        queryClient.invalidateQueries("me");
      }
    },
  });

  const onSubmit = () => {
    if (!loginMutation.isLoading) {
      const { name, password } = getValues();
      loginMutation.mutate({ name, password, role: UserRole.Tester });
    }
  };

  return (
    <div className="h-screen flex items-center justify-center  min-w-min select-none">
      <Helmet>
        <title>지원자 로그인</title>
      </Helmet>
      <video
        className="fixed top-0 bottom-0 left-0 right-0 object-fill z-0 h-screen w-full"
        playsInline
        autoPlay
        loop
        muted
      >
        <source src={"video/lgFullBG.mp4"} type="video/mp4" />
      </video>
      <div className=" bg-gray-900 w-full max-w-lg py-6 px-8 rounded text-center z-10 opacity-90 text-white">
        <h3 className=" text-2xl opacity-100">Presentation</h3>
        <div className="h-px bg-white my-4 "></div>
        <div className="text-sm">
          이름과 안내 받으신 인증키를 입력해 주십시오.
        </div>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="grid gap-3 mt-5 px-5"
        >
          <input
            {...register("name", { required: "이름은 필수 입력값 입니다." })}
            placeholder="이름"
            required
            className="text-center text-black bg-white shadow-inner focus:ring-2 focus:ring-gray-900 focus: outline-none my-1  py-2 px-3 rounded "
          />
          {errors.name?.message && (
            <FormError errorMessage={errors.name?.message} />
          )}
          <input
            {...register("password", {
              required: "인증키는 필수 입력값 입니다.",
            })}
            type="password"
            placeholder="인증키"
            required
            className="text-center text-black bg-white shadow-inner focus:ring-2 focus:ring-gray-900 focus: outline-none my-1  py-2 px-3 rounded "
          />
          {errors.password?.message && (
            <FormError errorMessage={errors.password?.message} />
          )}

          <div className="text-sm  text-left mb-1">
            본 과제의 내용 및 진형 프로세스 등 면접과 관련되 모든 사항을 어떠한
            방법으로도 외부에 유출하지 않을 것을 서약합니다.
          </div>

          <div className="text-sm  text-left mb-1">
            만약 외부 유출 시 관련 법령에 의거하여 처벌받을 수 있음을 확인
            하였습니다.
          </div>

          <div className="text-sm mb-1 font-bold">
            위의 내용에 동의합니다.{" "}
            <input
              onChange={(e) => setIsAgree(!isAgree)}
              type="checkbox"
              checked={isAgree}
            />
          </div>

          <FormButton
            canClick={isValid && isAgree}
            loading={loginMutation.isLoading}
            actionText={"접속"}
          />
          {loginMutation.data?.error && (
            <FormError errorMessage={loginMutation.data.error} />
          )}
          <div className="flex content-end justify-end">
            <img className="h-7" src={"image/lgDisplay.png"} alt="lg display" />
          </div>
        </form>
      </div>
    </div>
  );
};
