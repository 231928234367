import { io } from "socket.io-client";
import { Jwt } from "../constant";

export const socket = io("https://adminpt.lg.insahr.co.kr/", {
  transports: ["websocket"],
});
//export const socket = io("http://localhost:4100/", {
//  transports: ["websocket"],
//});
export const startTestToServer = () => {
  socket.emit("startTestToServer", { token: Jwt() });
};
